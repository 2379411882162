section#links-section {
    width: 80%;
    margin: auto;
    a {
        font-size: 14pt;
    }
    .col-lg-6 {
        padding: 40px;
    }
    img.dashboard-img {
        width: 100%;
    }
    img.enphase-img {
        width: 100%;
    }
}