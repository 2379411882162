    /*
    Flaticon icon font: Flaticon
    Creation date: 26/01/2019 10:20
    */

    @font-face {
      font-family: "Flaticon";
      src: url("../fonts/Flaticon.eot");
      src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Flaticon.woff2") format("woff2"),
        url("../fonts/Flaticon.woff") format("woff"),
        url("../fonts/Flaticon.ttf") format("truetype"),
        url("../fonts/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
      }
    }

    .fi:before {
      display: inline-block;
      font-family: "Flaticon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    .flaticon-compass:before {
      content: "\f100";
    }

    .flaticon-desk:before {
      content: "\f101";
    }

    .flaticon-bathroom:before {
      content: "\f102";
    }

    .flaticon-beach:before {
      content: "\f103";
    }

    $font-Flaticon-compass: "\f100";
    $font-Flaticon-desk: "\f101";
    $font-Flaticon-bathroom: "\f102";
    $font-Flaticon-beach: "\f103";