.sticky-wrapper {
	position: absolute;
	width: 100%;
	height: 80px !important;
	&.is-sticky {
		.default-header {
			background: $title-color;
			box-shadow: -21.213px 21.213px 30px 0px rgba(158, 158, 158, 0.3);
		}
	}
}

.default-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	z-index: 9;
	background: $title-color;
	.navbar {
		padding: 22px 16px;
		.navbar-toggler {
			border: 1px solid $white;
			border-color: $white;
			cursor: pointer;
			&:focus {
				outline: none;
			}
			span {
				color: $white;
			}
		}
	}
	.dropdown-item {
		font-size: 14px;
		width: auto !important;
		text-align: left;
		@media(max-width: 767px) {
			text-align: left;
			padding: 0.25rem;
		}
	
	}
	
	.dropdown-item {
		font-size: 14px;
		width: auto !important;
		text-align: left;
	
		@media(max-width: 767px) {
			text-align: left;
			padding: 0.25rem;
		}
	
	}
	
	@media (min-width: 991px) {
		.dropdown .dropdown-menu {
			display: none;
			opacity: 0;
			visibility: hidden;
			-webkit-transition: all 200ms ease-in;
			-moz-transition: all 200ms ease-in;
			-ms-transition: all 200ms ease-in;
			-o-transition: all 200ms ease-in;
			transition: all 200ms ease-in;
		}
	
		.dropdown:hover .dropdown-menu {
			display: block;
			opacity: 1;
			visibility: visible;
		}
	}
	
	
	.dropdown-menu {
		border-radius: 0;
		margin-top: 27px;
		border: none;
		@media (max-width: 991px) {
			margin-top: 0px;
		}
		a {
			padding: 5px 15px;
		}
	
		@media(max-width:767px) {
			margin-top: 0px;
		}
	}
	
	.dropdown-item:focus,
	.dropdown-item:hover {
		color: #16181b;
		text-decoration: none;
		background-color: transparent;
	}
}




.menu-bar {
    cursor: pointer;
    span {
        color: $black;
        font-size: 24px;
    }
}

@media(max-width: 992px) {
    .navbar-nav {
        height: auto;
        max-height: 400px;
        overflow-x: hidden;
    }
}

.main-menubar {
    display: none !important;
}

@include media-breakpoint-down(md) {
    .main-menubar {
        display: block !important;
    }
}

.navbar-nav {
    a {
		text-transform: uppercase;
		font-size: 13px;
        font-weight: 500;
        color: $white;
        // padding: 32px 20px;
        &:hover, &.active {
            color: $primary-color;
        }
    }
    @media(max-width:992px) {
        margin-top: 10px;
        a {
            padding: 0 !important;
        }
        li {
            padding: 10px 0;
        }
	}
	.dropdown {
		.dropdown-menu {
			@media (max-width: 991px) {
				background: transparent;
			}
		}
		.dropdown-item {
			color: $title-color;
			text-transform: capitalize;
			&:hover {
				color: $primary-color;
			}
			@media (max-width: 991px) {
				color: $white;
			}
		}
	}
}

.navbar-brand{
	img{
		height: 46px;
	}
}