
//    Font Family
$primary-font	: 'Roboto', sans-serif;
$title-font	    : 'Roboto Condensed', sans-serif;

/*--------------------------- Color variations ----------------------*/

$primary-color	: #cea06c;
$title-color	: #263a4e;
$link-color     : #2492ff;
$text-color		: #87898f;

$white			: #fff;
$offwhite		: #f7f7f7;
$black			: #222;






